import getConfig from 'next/config'
import { createClient } from 'contentful'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'

import { LineOfBusinessSpace } from './src/siteBuilder/lineOfBusiness/types'

// ENVIRONMENT VARIABLES
export const {
  IS_PROD,
  CONTENTFUL_SB2_CLIENT_CONFIG,
  CONTENTFUL_BLOG_BUILDER_CLIENT_CONFIG,
  CONTENTFUL_SB2_SLUG_CACHE_CLIENT_CONFIG,
  GLOBAL_ELEMENTS_HOST,
} = getConfig()?.serverRuntimeConfig

function optanonConsentAllow(cookie: string): string {
  if (cookie) {
    const regex = /groups=[^&]+/g
    const groups = cookie.match(regex)

    return groups ? groups.toString() : ''
  }
  return undefined
}

export const { TAG_MANAGER_URL, CONTENTFUL_BLOG_BUILDER_SPACE, CONTENTFUL_BLOG_BUILDER_DELIVERY_TOKEN } =
  getConfig()?.publicRuntimeConfig

export const siteBuilder: LineOfBusinessSpace = {
  label: 'Site Builder 2.0',
  lineOfBusiness: 'siteBuilder',
  get options() {
    return {
      client: createClient(CONTENTFUL_SB2_CLIENT_CONFIG),
      slugCacheClient: createClient(CONTENTFUL_SB2_SLUG_CACHE_CLIENT_CONFIG),
    }
  },
  getClient: (cookies: NextApiRequestCookies, transactiondId: string, forceContentfulFallbackCache: string) => {
    const optanonConsent = optanonConsentAllow(cookies.OptanonConsent)

    const filteredCookies = {
      'AMCV_67A50FC0539F0BBD0A490D45%40AdobeOrg': cookies['AMCV_67A50FC0539F0BBD0A490D45%40AdobeOrg'],
      referer: cookies.referer,
      agent: cookies.agent,
      ipAddress: cookies.ipAddress,
      kndctrIdentity: cookies['kndctr_67A50FC0539F0BBD0A490D45_AdobeOrg_identity'],
      kndctrCluster: cookies['kndctr_67A50FC0539F0BBD0A490D45_AdobeOrg_cluster'],
      OptanonConsent: optanonConsent,
    }

    const stringifiedFilteredCookies = JSON.stringify(filteredCookies)

    // If we are in vercel, we need to set the host to the Vercel URL and can set the insecure flag to false
    if (process.env.VERCEL === '1') {
      CONTENTFUL_SB2_CLIENT_CONFIG.host = process.env.VERCEL_URL
      CONTENTFUL_SB2_CLIENT_CONFIG.insecure = false
    }

    return createClient(
      Object.assign({}, CONTENTFUL_SB2_CLIENT_CONFIG, {
        resolveLinks: true,
        headers: {
          cookies: stringifiedFilteredCookies,
          'correlation-id': transactiondId,
          'force-contentful-fallback-cache': forceContentfulFallbackCache,
        },
      })
    )
  },
}

export const blogBuilder = {
  label: 'Blog Builder',
  lineOfBusiness: 'blogBuilder',
  getClient: () => {
    return createClient(
      Object.assign({}, CONTENTFUL_BLOG_BUILDER_CLIENT_CONFIG, {
        resolveLinks: true,
      })
    )
  },
}

export default {
  IS_PROD,
  TAG_MANAGER_URL,
  space: siteBuilder,
  blog: blogBuilder,
}
